/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Controller } from '@hotwired/stimulus'
import PDFObject from 'pdfobject'

class EditorController extends Controller<HTMLFormElement> {
  static targets = ['container', 'block']

  declare readonly blockTargets: HTMLDivElement[]
  declare readonly containerTarget: HTMLDivElement

  connect(): void {
    console.log('YO')
    this.processBlocks()
  }

  processBlocks() {
    for (const block of this.blockTargets) {
      if (block.dataset.type === 'pdfEmbed') {
        if (PDFObject.supportsPDFs) {
          block.style.height = '600px'
          PDFObject.embed(block.dataset.src, block)
        }
      }
    }
  }
}

export default EditorController
